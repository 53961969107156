module.exports = [{
      plugin: require('/home/paul/turn2/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/paul/turn2/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"https://piwik.cleanclothes.org","siteUrl":"https://turnaroundhm.org"},
    },{
      plugin: require('/home/paul/turn2/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":true},
    },{
      plugin: require('/home/paul/turn2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/paul/turn2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
