// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/paul/turn2/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-newsitem-js": () => import("/home/paul/turn2/src/templates/newsitem.js" /* webpackChunkName: "component---src-templates-newsitem-js" */),
  "component---src-pages-404-js": () => import("/home/paul/turn2/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-destination-js": () => import("/home/paul/turn2/src/pages/destination.js" /* webpackChunkName: "component---src-pages-destination-js" */),
  "component---src-pages-dossier-js": () => import("/home/paul/turn2/src/pages/dossier.js" /* webpackChunkName: "component---src-pages-dossier-js" */),
  "component---src-pages-faq-js": () => import("/home/paul/turn2/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-generic-js": () => import("/home/paul/turn2/src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("/home/paul/turn2/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/home/paul/turn2/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-take-action-js": () => import("/home/paul/turn2/src/pages/take-action.js" /* webpackChunkName: "component---src-pages-take-action-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/paul/turn2/.cache/data.json")

